import React, { useEffect } from "react";

import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "components/layout";

import Home from "sections/home/Home.js";
import About from "sections/about/About.js";
import Services from "sections/services/Services.js";
import Partners from "sections/partners/Partners.js";
import Testimonials from "sections/testimonials/Testimonials.js";
import Technologies from "sections/technologies/Technologies.js";
// import TeamOne from "sections/team/TeamOne.js";
import Contact from "sections/contact/Contact.js";

import "../lang/i18n";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../redux/slices/page";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const Index = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentPage("main"));
  }, [dispatch]);

  useEffect(() => {
    console.log(`
    ____  _     _                       _   _           
   / ___|(_)___| |_ ___ _ __ ___   __ _| |_(_) ___ __ _ 
   \\___ \\| / __| __/ _ | '_ \` _ \\ / _\` | __| |/ __/ _\` |
    ___) | \\__ | ||  __| | | | | | (_| | |_| | (_| (_| |
   |____/|_|___/\\__\\___|_| |_| |_|\\__,_|\\__|_|\\___\\__,_|
                                                        
  `);
    const queryParameters = new URLSearchParams(window.location.search);
    if (queryParameters.has("thankU")) {
      const url = new URL(window.location.href);
      window.history.pushState({}, null, url.pathname);
      Swal.fire({
        backdrop: "rgba(07, 28, 43, 0.85)",
        background: "#1C3258",
        color: "#2ba0ea",
        icon: "success",
        iconColor: "#f4d22a",
        titleText: t("contact.form.response1"),
      });
    }
  }, []);

  const { site } = props.data;

  return (
    <div>
      <Helmet>
        <title>{site.meta.title}</title>
        <meta name="title" content={site.meta.title} />
        {/* <meta name="description" content={site.meta.description} /> */}
        <meta name="keywords" content={site.meta.keywords}></meta>

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://sistematica.io/" />
        <meta property="og:title" content={site.meta.title} />
        <meta property="og:description" content={site.meta.description} />
        <meta property="og:image" content="img/preview.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://sistematica.io/" />
        <meta property="twitter:title" content={site.meta.title} />
        <meta property="twitter:description" content={site.meta.description} />
        <meta property="twitter:image" content="img/preview.png" />
      </Helmet>
      <Layout
        isHome={true}
        sections={[
          "home",
          "about",
          "services",
          "partners",
          /* "technologies",
            "team", */
          "solutions",
          "contact",
          "account",
          "language",
        ]}
      >
        <Home />
        <About />
        <Services />
        <Partners />
        {/* {<TeamOne />} */}
        <Technologies />
        <Testimonials />
        <Contact />
      </Layout>
    </div>
  );
};

export default Index;

export const myQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        keywords
      }
    }
  }
`;
